import { Flex, Box } from "@chakra-ui/react"

import HomeButton from "./components/homeButton"
import { ErrandsButton, RequestsButton, NewRequestButton, PlacesButton} from './components/actionButtons'

import { UITheme } from "../../others/colors"

export default function NavbarMob() {
    return (
        <>
        <Box style={{
            backgroundColor: UITheme(true).navBar,
            // backgroundColor: 'rgba(0,0,0,0.75)',
            width: '97%',
            borderRadius:'18px',
            height: '8vh',
            color:'white',
            padding: '3px',
            margin: '1.5% auto',
            backdropFilter:"blur(3px)",
            boxShadow:"0 5px 10px rgba(0, 0, 0, 0.5)",
            zIndex:99
        }}>
            <Flex justifyContent={'space-between'} marginTop='3px'>
                <Flex style={{boxSizing:"border-box", height:'100%', width:'100%', margin:'7px', marginLeft:'10px'}} justifyContent={'space-evenly'} gap='10px' alignItems={'center'}>
                    <NewRequestButton/>
                    <PlacesButton/>
                </Flex>
                <Flex alignItems='center' justifyContent={'center'}>
                    <HomeButton mobile />
                </Flex>
                <Flex style={{boxSizing:"border-box", height:'100%', width:'100%', margin:'7px', marginRight:'10px'}} justifyContent={'space-evenly'} gap='10px' alignItems={'center'} >
                    <ErrandsButton/>
                    <RequestsButton/>
                </Flex>
            </Flex>
        </Box>
        </>
    )
}